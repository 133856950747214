// Import styles
import "../../utils/styles/GlobalStyle.scss";
import "../Dashboard/dashboard.scss";
// Import hooks and utilities
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import isAuthenticated from "../../utils/auth/auth";
// Import component
import Navbar from "../../components/Navbar";

function Dashboard() {
  const navigate = useNavigate();

  // State for storing clients and loading state
  const [clients, setClients] = useState([]); // Initialize clients as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 8; // Adjust this number as needed

  const fetchClients = async () => {
    try {
      const response = await fetch("https://backend.goldassurance.fr/api/clients"); // URL de l'API
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération des données");
      }
      const data = await response.json();
      const allClients = [];

      // Process particuliers
      (data.particuliers || []).forEach((client) => {
        const clientInfo = {};
        client.questions.forEach((question) => {
          clientInfo[question.label.trim()] = question.reponse || "";
        });
        clientInfo._id = client._id;
        allClients.push(clientInfo);
      });

      // Process customers
      (data.customers || []).forEach((client) => {
        const clientInfo = {};
        client.questions.forEach((question) => {
          clientInfo[question.label.trim()] = question.reponse || "";
          if (question.label.trim() === "Qui sont les futurs assurés ?") {
            try {
              const futursAssures = JSON.parse(question.reponse);
              clientInfo["futursAssures"] = futursAssures.map((assure) => ({
                nom: assure.nom,
                prenom: assure.prenom,
              }));
            } catch (error) {
              clientInfo["futursAssures"] = [];
            }
          }
        });
        clientInfo._id = client._id;
        allClients.push(clientInfo);
      });

      // Process particulierAutos
      (data.particulierAutos || []).forEach((client) => {
        const clientInfo = {};
        client.questions.forEach((question) => {
          clientInfo[question.label.trim()] = question.reponse || "";
        });
        clientInfo._id = client._id;
        allClients.push(clientInfo);
      });

      // Process pros
      (data.pros || []).forEach((client) => {
        const clientInfo = {};
        client.questions.forEach((question) => {
          clientInfo[question.label.trim()] = question.reponse || "";
        });
        clientInfo._id = client._id;
        allClients.push(clientInfo);
      });

      // Process forms
      (data.forms || []).forEach((client) => {
        const clientInfo = {};
        client.questions.forEach((question) => {
          clientInfo[question.label.trim()] = question.reponse || "";
        });
        clientInfo._id = client._id;
        allClients.push(clientInfo);
      });

      // Tri par ID décroissant
      allClients.sort((a, b) => {
        if (a._id > b._id) return -1;
        if (a._id < b._id) return 1;
        return 0;
      });

      // Update the state with the list of clients
      setClients(allClients);
      setLoading(false); // Disable loading state
    } catch (err) {
      console.error("Erreur de récupération :", err);
      setError(err.message);
      setLoading(false); // Disable loading state in case of error
    }
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login"); // Redirect if not authenticated
    } else {
      fetchClients(); // Call API to fetch clients
    }
  }, [navigate]);

  // Pagination
  const totalPages = Math.ceil(clients.length / clientsPerPage);
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = clients.slice(indexOfFirstClient, indexOfLastClient);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Display while loading
  if (loading) {
    return <div>Chargement des clients...</div>;
  }

  // Display in case of error
  if (error) {
    return <div>Erreur : {error}</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="table-container">
          <h1>Liste des Clients</h1>
          <table className="client-table">
            <thead>
              <tr>
                <th>Noms/Societe</th>
                <th>Prénoms/Siren</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Détails</th>
              </tr>
            </thead>
<tbody>
  {currentClients.map((client) => {
    // Parse Assuré Principal if available
    let assurePrincipal = {};

    if (client["Assuré Principal"]) {
      try {
        assurePrincipal = JSON.parse(client["Assuré Principal"]);
      } catch (error) {
        console.error("Erreur lors du parsing de l'assuré principal", error);
      }
    }

    let futursAssures = [];
    // Parse futurs assurés if available
    if (client["Qui sont les futurs assurés ?"]) {
      try {
        futursAssures = JSON.parse(client["Qui sont les futurs assurés ?"]);
      } catch (error) {
        console.error("Erreur lors du parsing des futurs assurés", error);
      }
    }

    return (
      <tr key={client._id}>  {/* Gardez _id comme clé pour React */}
        <td>
          {/* Affiche le nom du premier futur assuré ou le nom du client */}
          {futursAssures.length > 0
            ? futursAssures[0].nom // Nom du premier futur assuré
            : client["Nom"] ||
              client["Nom de Societe"] ||
              assurePrincipal.nom ||
              ""}
        </td>
        <td>
          {/* Affiche le prénom du premier futur assuré ou le prénom du client */}
          {futursAssures.length > 0
            ? futursAssures[0].prenom // Prénom du premier futur assuré
            : client["Prenom"] ||
              client["Numero de Siren"] ||
              assurePrincipal.prenom ||
              ""}
        </td>
        <td>
          {/* Affiche l'email du premier futur assuré ou celui du client */}
          {futursAssures.length > 0
            ? futursAssures[0].email // Email du premier futur assuré
            : assurePrincipal.email || client["Email"] || "Non renseigné"}
        </td>
        <td>
          {/* Affiche le téléphone du premier futur assuré ou celui du client */}
          {futursAssures.length > 0
            ? futursAssures[0].telephone // Téléphone du premier futur assuré
            : assurePrincipal.telephone ||
              client["Telephone"] ||
              "Non renseigné"}
        </td>
        <td className="Background-detail">
          {/* Lien vers les détails du client, en utilisant l'ID pour la navigation */}
          <a href={`/detail-client/${client._id}`}>Détails</a>
        </td>
      </tr>
    );
  })}
</tbody>

          </table>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={paginate}
          />
        </div>
      </div>
    </div>
  );
}

// Pagination Component
function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <div className="pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        aria-label="Page Précédente"
      >
        Précédent
      </button>

      <span>
        Page {currentPage} sur {totalPages}
      </span>

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        aria-label="Page Suivante"
      >
        Suivant
      </button>
    </div>
  );
}

export default Dashboard;
